export const environment = {
    production: false,
    useApi: true,
    baseUri: 'https://api.vibes2-staging.apps4.icldig.icl-group.com/',
    dataApiEndpoint: 'getPaperChart',
    stocksAPIUrl: 'globes-api/getAllPapers',
    histogramStockAPIUrl: `globes-api/getPaperChart?paperid={{paperId}}`,
    adalConfig: {
      clientId: 'e260fc94-a43d-4864-aad3-51b5c21cb765',
      issuer: 'https://login.microsoftonline.com/802762d2-02c4-4677-98ba-54060a234204/v2.0',
      strictDiscoveryDocumentValidation: false,
      responseType: 'id_token token',
      scope: 'e260fc94-a43d-4864-aad3-51b5c21cb765/.default',
    },
    version: '2.1.3.4',
  };

